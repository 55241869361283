import React, { Component } from "react";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import "moment/locale/id.js";
import CurrencyFormat from "react-currency-format";
import { Link } from "react-router-dom";
import remSep from "string-remove-thousand-separators";

const moment = require("moment");

class Pendaftaran extends Component {
  constructor(props) {
    super(props);

    this.state = {
      student_id: "",
      is_show: false,
      note_verification: "",
      upp: "",
      potongan: 0,
      potongan6: 0,
      potongan7: 0,
      potongan8: 0,
      potongan9: 0,
      potongan10: 0,
      nominal: "",
      dp: "",
      termin: "1",
      angsuran: "",
      us: "",
      uk: "",
      ukg: "",
      pomg: "",
      totalus: "",
    };
  }

  handleClick = (param) => (e) => {
    e.preventDefault();

    localStorage.setItem("student_id", param);
    window.open("/detailstudent", "_blank");
  };
  getFiles(files) {
    this.setState({
      encrypt_64: files[0].base64,
      original_name: files[0].name,
    });
  }

  componentDidMount() {
    this.setState({
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
    });

    fetch(
      global.variable.LINK_API +
      "student/detail/" +
      localStorage.getItem("DATA_JALUR").split("-")[0],
      {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        //body: JSON.stringify(data),
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          this.setState(resData.data[0]);
          this.setState({ is_show: true });
          if (this.state.data_potongan && this.state.data_potongan.length) {
            this.setState({
              potongan6: this.state.data_potongan.length > 0 ? this.state.data_potongan[0].nominal : 0,
              potongan7: this.state.data_potongan.length > 1 ? this.state.data_potongan[1].nominal : 0,
              potongan8: this.state.data_potongan.length > 2 ? this.state.data_potongan[2].nominal : 0,
              potongan9: this.state.data_potongan.length > 3 ? this.state.data_potongan[3].nominal : 0,
              potongan10: this.state.data_potongan.length > 4 ? this.state.data_potongan[4].nominal : 0,
            });
          }
          if (this.state.data_bayar && this.state.data_bayar.length) {
            let potongan2 = parseFloat(this.state.data_bayar[0].potongan);
            //let potongan2 = (this.state.data_bayar[5].nominal + this.state.data_bayar[6].nominal + this.state.data_bayar[7].nominal + this.state.data_bayar[8].nominal)

            let nominal2 = parseFloat(this.state.data_bayar[0].nominal) - potongan2;
            let angsur = ((nominal2 - parseFloat(this.state.data_bayar[0].dp)) / parseFloat(this.state.data_bayar[0].termin)).toFixed(0);
            let total = parseFloat(this.state.data_bayar[1].nominal) + parseFloat(this.state.data_bayar[2].nominal);

            this.setState({
              upp: this.state.data_bayar[0].nominal,
              potongan: this.state.data_bayar[0].potongan,
              nominal: nominal2,
              dp: this.state.data_bayar[0].dp,
              termin: this.state.data_bayar[0].termin,
              angsuran: angsur,
              us: this.state.data_bayar[1].nominal,
              ukg: this.state.data_bayar[2].nominal,
              // potongan6:this.state.data_bayar[5].nominal, potongan7:this.state.data_bayar[6].nominal, potongan8:this.state.data_bayar[7].nominal, potongan9:this.state.data_bayar[8].nominal,
              totalus: total,
            });
          }

          // if(this.state.data_bayar[0]){
          //   this.setState({potongan:this.state.data_bayar[0].potongan})
          // }else {
          //   if(this.state.school_from.id == '1'){
          //     this.setState({potongan:'0'})
          //   }
          // }
        }
      });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeNom = (e) => {
    this.setState({
      [e.target.name]:
        e.target.value !== "" ? parseFloat(remSep(e.target.value)) : 0,
    });
  };

  onChangePotongan = (e) => {
    let potongan2 =
      parseFloat(this.state.potongan6) +
      parseFloat(this.state.potongan7) +
      parseFloat(this.state.potongan8) +
      parseFloat(this.state.potongan9) +
      parseFloat(this.state.potongan10);

    this.setState(
      { potongan: potongan2, nominal: this.state.upp - potongan2 },
      () => {
        let angsur = (this.state.nominal - this.state.dp) / this.state.termin;
        angsur = angsur.toFixed(0);
        this.setState({ angsuran: angsur });
      }
    );
  };
  onChangeTotal = (e) => {
    let total =
      parseInt(this.state.us) +
      parseInt(this.state.uk) +
      parseInt(this.state.ukg) +
      parseInt(this.state.pomg);
    this.setState({ totalus: total });
  };

  onDropdownSelected(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // handleSubmit = ()=> {
  //   global.swal({
  //       text: 'Apakah SK sudah di PRINT dan Anda yakin akan disubmit?',
  //       closeOnEsc: false,
  //       closeOnClickOutside: false,
  //       buttons: [
  //         'Cancel',
  //         {text: 'Submit',
  //         closeModal: false,}
  //       ],
  //     })
  //     .then((confirm) => {
  //       let errorMessage =  { code : 403, message : 'cancel' };
  //       if (!confirm) throw errorMessage;
  //     const data = { student_id :  localStorage.getItem('DATA_JALUR').split('-')[0], flow_id : 8, upp: this.state.upp, spp: this.state.us, kegiatan: (this.state.uk+this.state.ukg+this.state.pomg), totalus : this.state.totalus, potongan: this.state.potongan, finalupp : this.state.nominal}
  //     return fetch(global.variable.LINK_API + 'flow/update',
  //     {
  //       method: 'post',
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         'Authorization': 'Bearer '+ global.cookies.get('access_token'),
  //       },
  //       body: JSON.stringify(data),
  //     })

  //     .then(response =>  response.json())
  //     .then(resData => {
  //     if(resData.message==="Success"){

  //       global.swal("Success", 'Berhasil Submit', "success")
  //       .then((value) => {
  //       window.location.href="/wawancara";
  //       });
  //     }

  //     })

  //    })
  //     .catch(err => {
  //       if (err) {
  //       if(err.code!==403){
  //         global.swal("Error", "Koneksi ke server gagal.", "info")
  //       }
  //       } else {
  //       global.swal.stopLoading();
  //       global.swal.close();
  //       }
  //     });
  // }
  handleTolak = () => {
    global
      .swal({
        text: "Alasan tidak sepakat",
        content: "input",
        closeOnEsc: false,
        closeOnClickOutside: false,
        buttons: ["Cancel", { text: "Submit", closeModal: false }],
      })
      .then((confirm) => {
        let errorMessage = { code: 403, message: "cancel" };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
          flow_id: 98,
          note_reject: confirm,
        };
        return fetch(global.variable.LINK_API + "flow/update", {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + global.cookies.get("access_token"),
          },
          body: JSON.stringify(data),
        });
      })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global.swal("Success", "Berhasil Tolak", "success").then((value) => {
            window.location.href = "/wawancara";
          });
        }
      })
      .catch((err) => {
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info");
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  };

  handleSave = (param) => (event) => {
    event.preventDefault();

    if (this.state.upp === "") {
      global.swal("Error", "DPP harap diisi", "info");
    } else if (this.state.potongan6 === "") {
      global.swal("Error", "Potongan Early Bird harap diisi", "info");
    } else if (this.state.potongan7 === "") {
      global.swal("Error", "Potongan Prestasi harap diisi", "info");
    } else if (this.state.potongan8 === "") {
      global.swal("Error", "Potongan Langsung Lunas harap diisi", "info");
    } else if (this.state.potongan9 === "") {
      global.swal("Error", "Potongan Kakak Adik harap diisi", "info");
    } else if (this.state.nominal === "") {
      global.swal("Error", "Nominal harap diisi", "info");
    } else if (this.state.dp === "") {
      global.swal("Error", "DP harap diisi", "info");
    } else if (this.state.termin === "") {
      global.swal("Error", "Termin harap diisi", "info");
    } else if (this.state.angsuran === "") {
      global.swal("Error", "Angsuran harap diisi", "info");
    } else if (this.state.us === "") {
      global.swal("Error", "Uang Sekolah harap diisi", "info");
    } else if (this.state.ukg === "") {
      global.swal("Error", "Uang Kegiatan harap diisi", "info");
    }
    // else if(this.state.uk ==="")
    //   {global.swal("Error", "Uang Komputer harap diisi", "info")}
    else if (this.state.ukg === "") {
      global.swal("Error", "Uang Kegiatan harap diisi", "info");
    }
    // else if(this.state.pomg ==="")
    //   {global.swal("Error", "Uang Komite (POMG) harap diisi", "info")}
    else {
      // if(this.state.dp < this.state.nominal * 0.25)
      //   {global.swal("Error", "Uang DP harus lebih dari 25%", "info")}
      let item = [];
      let potongan = [];

      let pot = Object.create({});
      pot.code_bayar = "122";
      pot.nominal = this.state.potongan6;
      pot.student_id = this.state.student_id;
      potongan.push(pot);

      pot = Object.create({});
      pot.code_bayar = "123";
      pot.nominal = this.state.potongan7;
      pot.student_id = this.state.student_id;
      potongan.push(pot);

      pot = Object.create({});
      pot.code_bayar = "124";
      pot.nominal = this.state.potongan8;
      pot.student_id = this.state.student_id;
      potongan.push(pot);

      pot = Object.create({});
      pot.code_bayar = "125";
      pot.nominal = this.state.potongan9;
      pot.student_id = this.state.student_id;
      potongan.push(pot);

      pot = Object.create({});
      pot.code_bayar = "158";
      pot.nominal = this.state.potongan10;
      pot.student_id = this.state.student_id;
      potongan.push(pot);

      let items = Object.create({});
      items.id = "58";
      items.name = "UPP";
      items.nominal = this.state.upp;
      items.potongan = this.state.potongan;
      items.dp = this.state.dp;
      items.termin = this.state.termin;
      item.push(items);

      items = Object.create({});
      items.id = "56";
      items.name = "Uang Sekolah";
      items.nominal = this.state.us;
      items.potongan = 0;
      items.dp = 0;
      items.termin = 0;
      item.push(items);

      // items =  Object.create({});
      // items.id = "3";
      // items.name = "Uang Komputer";
      // items.nominal = this.state.uk;
      // items.potongan = 0;
      // items.dp = 0;
      // items.termin= 0;
      // item.push(items);

      items = Object.create({});
      items.id = "57";
      items.name = "Uang Kegiatan";
      items.nominal = this.state.ukg;
      items.potongan = 0;
      items.dp = 0;
      items.termin = 0;
      item.push(items);

      // items =  Object.create({});
      // items.id = "5";
      // items.name = "Uang Komite(POMG)";
      // items.nominal = this.state.pomg;
      // items.potongan = 0;
      // items.dp = 0;
      // items.termin= 0;
      // item.push(items);

      const data2 = {
        data: JSON.stringify(item),
        potongan: JSON.stringify(potongan),
        student_id: this.state.student_id,
        note_verification: this.state.note_verification,
        interview_status: param,
      };
      fetch(global.variable.LINK_API + "wawancara/store", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + global.cookies.get("access_token"),
        },
        body: JSON.stringify(data2),
      })
        .then((response) => response.json())
        .then((resData) => {
          if (resData.message === "Success") {
            global.swal("Success", resData.message, "success").then((value) => {
              window.location.href = "/wawancaradetail";
            });
          }
        });
    }
  };

  handlePrint = (e) => {
    e.preventDefault();
    localStorage.setItem("flow_id", 8);
    localStorage.setItem("upp", this.state.upp);
    localStorage.setItem("spp", this.state.us);
    localStorage.setItem(
      "kegiatan",
      this.state.uk + this.state.ukg + this.state.pomg
    );
    localStorage.setItem("totalus", this.state.totalus);
    localStorage.setItem("potongan", this.state.potongan);
    localStorage.setItem("finalupp", this.state.nominal);
    window.location.href = "/cetakskfinansial";
  };

  // handleNext = () => {

  //   window.location.href="/verifikasi";
  // }

  download = (param) => (e) => {
    e.preventDefault();
    window.open(
      "/image/" + param,
      "_blank",
      "location=yes,height=600,width=600,scrollbars=yes,status=yes"
    );

    //var newTab = window.open(param, "_blank", 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
    //newTab.document.body.innerHTML = '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNk+M9QDwADhgGAWjR9awAAAABJRU5ErkJggg==" width="100px" height="100px">';
  };

  form1() {
    let schoolUnit = JSON.parse(
      localStorage.getItem("DATA_PARAMETER")
    ).prmSchoolUnit;
    return (
      <div>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Jalur</Form.Label>
          <Form.Control
            type="Text"
            name="fullname"
            maxLength="100"
            value={this.state.registration_type.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>
            Nama Lengkap (Nama siswa dapat di klik untuk melihat detail data
            siswa)
          </Form.Label>
          <Form.Control
            type="Text"
            name="fullname"
            maxLength="100"
            onClick={this.handleClick(this.state.id)}
            value={(this.state.fullname.toUpperCase())}
            style={{ color: "blue" }}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Nama Panggilan</Form.Label>
          <Form.Control
            type="Text"
            name="nickname"
            readOnly
            maxLength="100"
            value={(this.state.nickname).toUpperCase()}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Alamat</Form.Label>
          <Form.Control
            type="Text"
            name="address"
            maxLength="200"
            value={this.state.address}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RT</Form.Label>
          <Form.Control
            type="Text"
            name="rt"
            maxLength="5"
            value={this.state.rt}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>RW</Form.Label>
          <Form.Control
            type="Text"
            name="rw"
            maxLength="5"
            value={this.state.rw}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kelurahan</Form.Label>
          <Form.Control
            type="Text"
            name="sub_district"
            maxLength="100"
            value={this.state.sub_district}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kecamatan</Form.Label>
          <Form.Control
            type="Text"
            name="district"
            maxLength="100"
            value={this.state.district}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Kabupaten/Kota</Form.Label>
          <Form.Control
            type="Text"
            name="sub_province"
            maxLength="100"
            value={this.state.sub_province}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Tinggal Dengan</Form.Label>
          <Form.Control
            type="Text"
            name="stay_id"
            maxLength="2"
            value={this.state.stay.name}
            disabled
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Moda Transportasi</Form.Label>
          <Form.Control
            type="Text"
            name="transportation"
            maxLength="2"
            value={this.state.transportation_id}
            disabled
          />
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>No. Handphone</Form.Label>
            <Form.Control
              type="Text"
              name="hp"
              maxLength="2"
              value={this.state.hp}
              disabled
            />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Form.Label>Anak ke</Form.Label>
            <Form.Control
              type="Text"
              name="child"
              maxLength="2"
              value={this.state.child}
              disabled
            />
          </Form.Group>
          {(schoolUnit.name !== "TK") && (
            <>
              <Form.Label>Sekolah Asal</Form.Label>
              <Form.Control
                type="Text"
                name="school_from"
                maxLength="2"
                value={this.state.school_from.name}
                disabled
              />
            </>
          )}
        </Form.Group>
        {(schoolUnit.name === "SMP" || schoolUnit.name === "SMA" || schoolUnit.name === "SD") && (
          <>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Nama Sekolah Asal</Form.Label>
              <Form.Control
                type="Text"
                name="school_from_name"
                maxLength="2"
                value={this.state.school_from_name}
                disabled
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Alamat Sekolah Asal</Form.Label>
              <Form.Control
                type="Text"
                name="school_from_address"
                maxLength="2"
                value={this.state.school_from_address}
                disabled
              />
            </Form.Group>
          </>
        )}
        {/* <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Uang SPP Sekolah Asal</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.spp} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form>     
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>Daya Listrik</Form.Label>
          <Form.Control 
            type="Text"
            name="electrical_power_id"
            maxLength="2"
            value={this.state.electrical_power?this.state.electrical_power.name:''}
            disabled  />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label>Biaya rata-rata listrik per bulan</Form.Label>
          <Form>
            <CurrencyFormat value={this.state.bill_pln} style={{ width : '100%'}} thousandSeparator={true} prefix={'Rp. '} disabled />
          </Form> 
        </Form.Group> */}
        <Form.Group></Form.Group>
      </div>
    );
  }

  form4() {
    let guardian = this.state.guardians;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].nik}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Orang Tua{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].guardian_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama Lengkap{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {(guardian[i].fullname).toUpperCase()}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Pekerjaan{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].occupation_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Penghasilan{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              <CurrencyFormat
                value={guardian[i].salary}
                displayType={"text"}
                style={{ width: "100%" }}
                thousandSeparator={true}
                prefix={"Rp. "}
                disabled
              />{" "}
            </Form.Label>
          </div>
          {/* <div className='rowHorizontal'>
            <Form.Label className="col-md-3" style={{width: 200, margin:5}}>Alamat </Form.Label>
            <Form.Label className="col-md-3" style={{margin:5,}}>{guardian[i].address} </Form.Label>
          </div> */}
        </div>
      );
    }

    return showitem;
  }

  form5() {
    let guardian = this.state.sibling;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Kelas{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].class}{" "}
            </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  form6() {
    let guardian = this.state.achieves;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <div key={guardian[i].name}>
          <hr />
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Jenis{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].achieve_type_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Nama Prestasi{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Penyelenggara{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].promoter}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Tingkat{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].achieve_rank_name}{" "}
            </Form.Label>
          </div>
          <div className="rowHorizontal">
            <Form.Label className="col-md-3" style={{ width: 200, margin: 5 }}>
              Peringkat{" "}
            </Form.Label>
            <Form.Label className="col-md-6" style={{ margin: 5 }}>
              {guardian[i].rank}{" "}
            </Form.Label>
          </div>
        </div>
      );
    }

    return showitem;
  }

  formdokumen() {
    let guardian = this.state.upload_documents;
    let showitem = [];

    for (let i = 0; i < guardian.length; i++) {
      showitem.push(
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>
            {this.state.upload_documents[i].document_upload_name}
          </Form.Label>
          <div>
            {/*<Image alt="Dokumen" style={{width:400}} src={this.state.upload_documents[i].encrypt_64} thumbnail />*/}

            <Link
              target="_blank"
              onClick={this.download(this.state.upload_documents[i].id)}
            >
              Lihat Dokumen
            </Link>
          </div>
        </Form.Group>
      );
    }
    return showitem;
  }

  formbukti() {
    return (
      /*<Image alt="Bukti transfer" style={{width:400}} src={this.state.upload_transaction.encrypt_64} thumbnail />*/

      <Link
        target="_blank"
        onClick={this.download(this.state.upload_transaction.id)}
      >
        Lihat Bukti Bayar
      </Link>
    );
  }

  handleTest() {
    const data = {
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      flow_id: 4,
      note_verification: this.state.note_verification,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          let m = moment();
          m = moment(resData.data.schedule_test_at, "YYYY-MM-DD").format(
            "DD-MM-YYYY"
          );
          global
            .swal(
              "Success",
              "Jadwal test adalah " +
              m +
              "\n Pada pukul 13.00 – 15.00 WIB \nDi ruang " +
              resData.data.classroom_id,
              "success"
            )
            .then((value) => {
              window.location.href = "/verifikasibuktibayar";
            });
        }
      });
  }

  handleConfirm = (event) => {
    const data = {
      student_id: localStorage.getItem("DATA_JALUR").split("-")[0],
      flow_id: 100,
      note_verification: this.state.note_verification,
    };

    fetch(global.variable.LINK_API + "flow/update", {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + global.cookies.get("access_token"),
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((resData) => {
        if (resData.message === "Success") {
          global
            .swal("Success", "Berhasil Diterima", "success")
            .then((value) => {
              window.location.href = "/wawancara";
            });
        }
      });
  };

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Wawancara</Breadcrumb.Item>
                  </Breadcrumb>

                  <Form>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Nama Siswa</Form.Label>
                      <Form.Control
                        type="Text"
                        name="jalur"
                        value={(localStorage.getItem("DATA_JALUR")).toUpperCase()}
                        disabled
                      />
                    </Form.Group>
                    {this.state.is_show ? this.form1() : ""}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Data Orang Tua
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.form4() : ""}

                    {this.state.is_show ? (
                      <div>
                        <hr />
                        <Form.Label
                          style={{
                            fontSize: 20,
                            textDecorationLine: "underline",
                          }}
                        >
                          Saudara yang masih bersekolah di{" "}
                          Sekolah Ricci
                          {/* {this.state.nama_sekolah} */}
                        </Form.Label>
                      </div>
                    ) : (
                      ""
                    )}

                    {this.state.is_show ? this.form5() : ""}

                    <div>
                      <hr />
                      <Form.Label
                        style={{
                          fontSize: 20,
                          textDecorationLine: "underline",
                        }}
                      >
                        Catatan Tentang Pendaftar
                      </Form.Label>
                    </div>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Catatan</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="5"
                        name="note_verification"
                        value={this.state.note_verification || ''}
                        onChange={this.onChange}
                      />
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>DPP</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="upp"
                          onChange={this.onChangeNom}
                          required
                          onBlur={this.onChangePotongan}
                          value={this.state.upp || 0}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Potongan Early Bird</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan6"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan6}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Potongan Prestasi</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan7"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan7}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Potongan Langsung Lunas</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan8"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan8}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Potongan Kakak Adik</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan9"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan9}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Potongan DPP Tambahan</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan10"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan10}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput">
                      <Form.Label>Total Potongan</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="potongan"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.potongan}
                          maxLength="3"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                          required
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Nominal</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="nominal"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.nominal || 0}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>DP</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="dp"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.dp || 0}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Termin</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="termin"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.termin}
                          maxLength="2"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Angsuran</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="angsuran"
                          decimalScale="0"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangePotongan}
                          value={this.state.angsuran}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>

                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Uang Sekolah</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="us"
                          onChange={this.onChangeNom}
                          onBlur={this.onChangeTotal}
                          value={this.state.us}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    {/* <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Komputer</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="uk" 
                              readOnly
                              onChange={this.onChangeNom} 
                              value={this.state.uk}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group> */}
                    <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Uang Kegiatan</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="ukg"
                          //readOnly
                          onChange={this.onChangeNom}
                          onBlur={this.onChangeTotal}
                          value={this.state.ukg || 0}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group>
                    {/* <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Uang Komite(POMG)</Form.Label>
                            <Form>
                            <CurrencyFormat 
                              input="number"
                              name="pomg"
                              readOnly 
                              onChange={this.onChangeNom} 
                              value={this.state.pomg}
                              maxLength="25"  thousandSeparator={true}
                              style={{ width : '100%'}} />
                            </Form>
                          </Form.Group> */}
                    {/* <Form.Group controlId="exampleForm.ControlInput1">
                      <Form.Label>Total Uang Sekolah</Form.Label>
                      <Form>
                        <CurrencyFormat
                          input="number"
                          name="totalus"
                          readOnly
                          onChange={this.onChangeNom}
                          onBlur={this.onChangeTotal}
                          value={this.state.totalus}
                          maxLength="25"
                          thousandSeparator={true}
                          style={{ width: "100%" }}
                        />
                      </Form>
                    </Form.Group> */}
                    <Form.Group>
                      <div className="rowHorizontal">
                        <Button
                          disabled={this.state.interview_status_id === "1"}
                          onClick={this.handleTolak}
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "red",
                          }}
                        >
                          Tidak Sepakat
                        </Button>
                        <Button
                          disabled={this.state.interview_status_id === "1"}
                          onClick={this.handleSave("2")}
                          type="submit"
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "#e6e600",
                          }}
                        >
                          Banding
                        </Button>
                        <Button
                          onClick={this.handleSave("1")}
                          block
                          style={{
                            margin: 5,
                            height: 50,
                            backgroundColor: "#33cc33",
                          }}
                        >
                          Sepakat
                        </Button>
                      </div>

                      {this.state.data_bayar &&
                        this.state.interview_status_id === "1" &&
                        this.state.data_bayar.length ? (
                        <div>
                          <div className="rowHorizontal">
                            <Button
                              onClick={this.handlePrint}
                              block
                              style={{ margin: 5, height: 50 }}
                            >
                              Print
                            </Button>
                          </div>
                          {/* <div className='rowHorizontal'>
                
                           <Button onClick={this.handleSubmit}  block style={{margin:5, height:50}} >Submit</Button>
                         </div> */}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Pendaftaran;
